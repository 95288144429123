import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [2,3,4];

export const dictionary = {
		"/": [~5],
		"/(app)/admin": [6,[2,3]],
		"/(app)/admin/movies": [~7,[2,3]],
		"/(app)/admin/movies/add": [8,[2,3]],
		"/(app)/admin/series/add": [9,[2,3]],
		"/(app)/admin/users": [~10,[2,3]],
		"/(app)/admin/users/add": [11,[2,3]],
		"/check-compatibility": [22],
		"/(app)/movies": [~12,[2]],
		"/(app)/movies/search": [~14,[2]],
		"/(app)/movies/[slug=slug]": [~13,[2]],
		"/register/[userId=uuidv7]": [~23],
		"/(app)/series": [~15,[2]],
		"/(app)/series/[slug=slug]": [~16,[2]],
		"/(app)/series/[slug=slug]/s[seasonNumber=number]/e[episodeNumber=number]": [~17,[2]],
		"/(app)/settings": [18,[2,4]],
		"/(app)/settings/authentication": [~19,[2,4]],
		"/(app)/settings/profile": [20,[2,4]],
		"/(app)/settings/tmdb": [~21,[2,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';